import dayjs from "dayjs";

import { AiOutlineHome, AiOutlineTrophy, AiOutlineUser } from "react-icons/ai";
import { BiBookBookmark, BiUserPin } from "react-icons/bi";
import { HiOutlineAcademicCap } from "react-icons/hi";
import { MdWorkOutline } from "react-icons/md";


const config = {
	isProduction: import.meta.env.PROD,
	apiUrl: import.meta.env.VITE_APP_API_URL,
	appUrl: import.meta.env.VITE_APP_URL,
	notificationsApiUrl: import.meta.env.VITE_APP_NOTIFICATION_URL,
	authenticationApiUrl: import.meta.env.VITE_APP_AUTHENTICATION_URL,
	badgeApiUrl: import.meta.env.VITE_APP_BADGE_URL,
	assetsPath: import.meta.env.VITE_BASE_URL,
	adminUrl: import.meta.env.VITE_APP_ADMIN_URL,
	cookieName: "iub_pref",
	loginPath: "/login",
	basepath: "/",
	onboardingPath: "/onboarding/master",
	cloudinaryFetchPath: "https://res.cloudinary.com/start2impact/image/fetch/",
	avatarPlaceholderImageUrl: "https://res.cloudinary.com/start2impact/image/uplo…v1666605907/Piattaforma/Img_Profilo-04_envhln.svg",
	basePlans: ["m1-o30", "m1-o31", "m1-o34", "m1-o40"],
	daysShowWelcomeVideoAfterRegistration: 7,
	googleAnalyticsTrackerId: "UA-94118148-1",
	tutorAImessageMaxLenght: 50000,
	userProfilePicPlaceholder: "https://res.cloudinary.com/start2impact/image/upload/v1666605907/Piattaforma/Img_Profilo-04_envhln.svg",
	menuItems: (plan = "m1-o18", role = "student") => (plan === "m1-o26" // plan===piano orientamento
		? [
			{
				id: 1,
				name: "Home",
				title: "Home | start2impact",
				to: "/",
				active: true,
				isInternal: true,
				icon: AiOutlineHome,
			},
			{
				id: 2,
				name: "Career Counseling",
				title: "Corso Career Counseling | start2impact",
				to: "/corso/career-counseling",
				active: false,
				isInternal: false,
				icon: MdWorkOutline,
			},
			{
				id: 3,
				name: "Account",
				title: "",
				to: role === "admin" ? `${config.adminUrl}${config.isProduction ? "/admin" : ""}/profile-edit` : "/subscribe/index",
				active: false,
				isInternal: false,
				icon: AiOutlineUser,
			},
		]
		: [
			{
				id: 1,
				name: "Home",
				title: "Home | start2impact",
				to: "/",
				active: true,
				isInternal: true,
				icon: AiOutlineHome,
			},
			{
				id: 2,
				name: "Master",
				title: "Scegli il tuo Master | start2impact",
				to: "/master",
				active: false,
				isInternal: true,
				icon: HiOutlineAcademicCap,
			},
			{
				id: 3,
				name: "Corsi",
				title: "Tutti i Corsi | start2impact",
				to: "/corsi",
				active: false,
				isInternal: true,
				icon: BiBookBookmark,
			},
			{
				id: 4,
				name: "Classifica",
				title: "Classifica | start2impact",
				to: "/classifica",
				active: false,
				isInternal: true,
				icon: AiOutlineTrophy,
			},
			{
				id: 5,
				name: "Community",
				title: "Community | start2impact",
				to: "/community",
				active: false,
				isInternal: true,
				icon: BiUserPin,
			},
			{
				id: 6,
				name: "Career Service",
				title: "",
				to: `${config.basePlans.includes(plan) ? "/career-service-base" : "/career-service"}`,
				active: false,
				isInternal: true,
				icon: MdWorkOutline,
				isNew: dayjs().isBefore(dayjs("2025-01-04")),
			},
			{
				id: 7,
				name: "Account",
				title: "",
				to: role === "admin" ? `${config.adminUrl}${config.isProduction ? "/admin" : ""}/profile-edit` : "/subscribe/index",
				active: false,
				isInternal: false,
				icon: AiOutlineUser,
			},
		]),
};


export default config;
